@font-face {
    font-family: Futura-LT-Heavy;
    src: url("./assets/fonts/FuturaHeavy.eot"), url("./assets/fonts/FuturaHeavy.ttf");
}

@font-face {
    font-family: Futura-LT-Medium;
    src: url("./assets/fonts/FuturaMedium.eot"), url("./assets/fonts/FuturaMedium.ttf");
}

@font-face {
    font-family: Futura-LT-Bold;
    src: url("./assets/fonts/FuturaLT-Bold.eot"), url("./assets/fonts/FuturaLT-Bold.ttf");
}

@font-face {
    font-family: Futura-LT-Book;
    src: url("./assets/fonts/FuturaLT-Book.eot"), url("./assets/fonts/FuturaLT-Book.ttf");
}

@font-face {
    font-family: Futura-LT-light;
    src: url("./assets/fonts/FuturaLT-Light.eot"), url("./assets/fonts/FuturaLT-Light.ttf");
}




/***********************************************GENERAL**************************************/

body {
    margin: 0;
    padding: 0;
    font-family: Futura-LT-Medium, Helvetica, Arial, sans-serif !important;
    background-color: #a2c2cd;
}

::placeholder {
    color: #fff !important;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #fff !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
   color: #fff !important;
}


span {
    color: black;
}

h4 {
    font-weight: normal;
    letter-spacing: 0.01em;
    font-size: 23px;
    line-height: 1.5;
}

h5{
    font-size: 14px;
}

label{
    font-weight: 700;
}

p {
    color: #0a0c24;
    text-align: left;
}

video {
    width: 100%;
    height: auto;
  }

.no-scroller{
    overflow-x: hidden !important;
}

.cursor{
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.iwSUvz {
    width: 250px !important;
    max-height: 42px !important;
}

.logo-mccann-home{
    text-align: center;
    background-color: #000;
    height: 131px;
}
 .logo-mw-home{}

.decoration-none{
    text-decoration: none;
}
.decoration-none:hover{
    /* text-decoration: underline; */
}

hr{
    opacity: 1;
}
/***********************************************loading**************************************/
.div-transform{
    height: 100vh;
    min-height:600px;
  }

.div-transform > div{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.loader {
    width: 20em;
    height: 20em;
    font-size: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader .face {
    position: absolute;
    border-radius: 50%;
    border-style: solid;
    animation: animate 3s linear infinite;
}

.loader .face:nth-child(1) {
    width: 100%;
    height: 100%;
    color: #000;
    border-color: currentColor transparent transparent currentColor;
    border-width: 0.2em 0.2em 0em 0em;
    --deg: -45deg;
    animation-direction: normal;
}

.loader .face:nth-child(2) {
    width: 70%;
    height: 70%;
    color: #234e78;
    border-color: currentColor currentColor transparent transparent;
    border-width: 0.2em 0em 0em 0.2em;
    --deg: -135deg;
    animation-direction: reverse;
}

.loader .face .circle {
    position: absolute;
    width: 50%;
    height: 0.1em;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: rotate(var(--deg));
    transform-origin: left;
}

.loader .face .circle::before {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    content: '';
    width: 1em;
    height: 1em;
    background-color: currentColor;
    border-radius: 50%;
    box-shadow: 0 0 2em,
                0 0 4em,
                0 0 6em,
                0 0 8em,
                0 0 10em,
                0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes animate {
    to {
        transform: rotate(1turn);
    }
}




/*************************************************SLIDER***************************************/

.carousel-control-next, .carousel-control-prev {
    top: 1%;
}

.carousel .carousel-control-next-icon {
    background-image: url("./Initial/images/right.png") !important;
}

.carousel .carousel-control-prev-icon {
    background-image: url("./Initial/images/left.png") !important;
}

.margin-slider{
    margin-top: 210px;
}

/***********************************************GENERAL PÁGINAS**********************************************/

.hrwhite{
    border-top: 2px solid #fff;
    width: 10%;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tituloPrincipal {
    font-family: Futura-LT-Bold, Futura, Helvetica, Arial, sans-serif;
    letter-spacing: 0.2em;
    font-size: 34px;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 25px;
}

.title-blue{
    color: #a2c2cd;
}

.fa-angle-right{
    color: #1b4e86 !important;
}

.a-black{
    color: #000 !important;
}

.a-white{
    color: #fff;
}

.carousel-innersmall img {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    filter: grayscale(100%);
}

.tile-slide-container {
    width: 100%;
}

.cards-innersmall img {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.title-cosas {
   
    font-weight: 400;
    letter-spacing: .2em;
    position: absolute;
    padding: 5%;
    /*background-color: white;*/
    font-size: 14px !important;
    line-height: 1.3;
}

.title {
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: .2em;
    position: absolute;
    padding: 5%;
    margin-top: 15%;
    left: 5%;
    /*background-color: white;*/
    font-size: 14px !important;
    line-height: 1.3;
    text-transform: uppercase;
    width: 100%;
}

.img-fluid {
    width: 20%;
}

.image-responsive{
    display: block;
    max-width: 100%;
    height: auto;
}

.aBlue:not([href]):not([tabindex]) {
    color: #1b4e86!important;
    text-decoration: none;
}

.aBlue:link, .aBlue:visited, .aBlue:hover, aBlue:focus,aBlue:active  {
    color: #1b4e86 !important;
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
}

.hrb {
    border-top: solid 3px #a2c2cd;
}

.hrb2 {
    border-top: solid 3px #a2c2cd ;
    width: 50%;
}

.hrb4 {
    border-top: solid 3px #000;
    width: 5%;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 0px;
    float: left;
}

.hrb5 {
    border-top: solid 3px #000;
    width: 5%;
    padding-bottom: 20px;
}

.hrb8 {
    border-top: solid 3px #000;
    width: 55%;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0px;
}

.hr-sweet {
    border-top: solid 1px #fff;
}
.img__wrap {
    position: relative;
    /* height: 350px; */
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: -30px;
}

.img__description {
    position: absolute;
    top: 0;
    bottom: 23px;
    width: 100%;
    /* height: 350px; */
    background: white;
    color: #fff;
    visibility: hidden;
     opacity: 0.1;
    transition: opacity .2s, visibility .2s;
}




.img__wrap:hover .img__description {
    visibility: visible;
    background-color: #1b4e86;
    opacity: 0.8;
}

.img__wrap-cards {
    position: relative;
}

.img__wrap-cards:hover .img__description{
    visibility: visible;
    background-color: #1b4e86;
    opacity: 0.9;
    width: 94%;
}

.breadcrumbs span {
    padding-top: 20px;
    display: inline-block;
    padding-left: -10px;
    vertical-align: top;
    display: inline-block;
    vertical-align: top;
    font-family: Futura-LT-light, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.03em;
    font-size: 13px;
    color: #9c9c9c;
}

.pjus {
    letter-spacing: 0.06em;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #0a0c24;
}

.pjus > p strong {
    font-family: Futura-LT-Bold, Futura, Helvetica, Arial, sans-serif;
}

.pjus > p a:link {
    color: #1b4e86;
}

.img-card2 {
    margin-left: 6%;
    width: 400px;
    max-height: 250px;
    min-height: 250px;
}

.message-news {
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.01em;
    font-size: 23px;
    text-transform: uppercase;
    line-height: 1.5;
    color:#000;
    margin-top: 10px;
    margin-bottom: 0px;
}

.post-alert {
    padding: 8px;
    background-color: blueviolet;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 10px;
    width: 400px;
    right: 65px;
    bottom: 35px;
}

.btn-floating:hover .post-alert {
    display: block !important;
    position: fixed;
    letter-spacing: 0.1em;
    animation: pound 2s infinite;
}

.btn-floating {
    text-align: center;
    color: #fff;
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    z-index: 1;
}
.modal-header {
    background-color: #a2c2cd;
    color: #fff;
}
/* .modal {
    text-align: left;
    padding: 0!important;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
}


.modal-body {
    max-height: calc(103vh - 210px);
    overflow-y: auto;
    font-size: 15px;
} */


.left {
    color: black;
    text-align: left;
}

.bgb2 {
    /* margin-top: 25px; */
    min-height: 200px;
    background-color: #a2c2cd;
    width: 120%;
    /* margin-left: -13%; */
    /* padding: 25px 20px 20px 40px; */
}


/********************************************BUTTONS HOME *****************************/

.buttons-img-home{
    display: block!important;
    margin: 0 auto;
}

.photo-grid-container {
    display: flex;
    justify-content: center;
}

.photo-grid {
    width: 900px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.photo-grid-item {
    border: none;
    width: 300px;
    height: 270px;
    text-align: center;
    cursor: pointer;
}

.first-item {
    order: 1;
}

.last-item {
    order: -1;
}

.title-buttons{
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
}


/***********************************************PROVEEDORES********************************************/

.proveedores {
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
    /* font-weight: 400; */
    position: absolute;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    /*background-color: white;*/
    font-size: 16px !important;
    line-height: 1.3;
    width: 100%;
}

.btn {
    font-size: 14px !important;
    color:#000 !important;
}


.btn-proveedores {
    width: 100px !important;
    height: 40px !important;
    background-color:  #000!important;
    text-transform: uppercase !important;
    font-size: 14px ;
    padding-top: 10px;
    border-radius: 2px !important;
    margin-top: -65px !important;
    color: #fff !important;
    text-align: center !important;
    font-weight: 400 !important;
    cursor: pointer;
    float: right;
}

.btn-videos-bienestar{
        text-transform: uppercase !important;
        font-size: 27px;
        color: #fff !important;
        text-align: center !important;
        font-weight: 400 !important;
        cursor: pointer;
        text-decoration: none;
}

.img__wrap2 {
    position: relative;
    height: 250px;
}

.prov_description {
    position: absolute;
    top: 0;
    bottom: 20px;
    width: 100%;
    height: 100%;
    color: #fff;
    visibility: hidden;
    opacity: 0.95;
    /* transition: opacity .2s, visibility .2s; */
}

.img__wrap:hover .prov_description {
    visibility: visible;
    background-color: #1b4e86;

}

.img__wrap2:hover .prov_description {
    visibility: visible;
    background-color:  #1b4e86;


}

.w30p {
    width: 100%;
    float: left;
    position: relative;
}


.img-largo{
width: 100%;
}

.texto-alto{
    height: 140px !important;
}

.bg-black{
    background-color: #000;
}
/***************************************MENU********************************/
.z-index{
    z-index: 9;
}

.margin-search{
    margin-top: 0px;
}

.bg-dark {
    background-color: #000!important;
}

.active{
    color: #a2c2cd  !important;
    text-decoration: underline;
}



.li-menu{
    width: 230px;
    /* text-align: center; */
    font-size: 20px;
    font-family: 'Futura LT W01 Book', Futura, Helvetica, Arial, sans-serif;
}

.li-menu-1{
    width: 140px;
    /* text-align: center; */
    font-size: 20px;
    font-family: 'Futura LT W01 Book', Futura, Helvetica, Arial, sans-serif;
}

.li-menu-2{
    width: 175px;
    /* text-align: center; */
    font-size: 20px;
    font-family: 'Futura LT W01 Book', Futura, Helvetica, Arial, sans-serif;
}

.bg-menu {
    background: #000 !important;
    height: 200px;
}
.navbar-toggler-icon {
    color: #fff !important;
}

.nav-link {
    color: #fff !important;
}

.logo-menu{
    margin-left: 70px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #a2c2cd !important;
}
.dropdown-item:hover {
    color: #fff;
    background-color: #a2c2cd ;
}
.dropdown-item {
    color: #fff;
    border-bottom: solid 1.5px #1e1e1e;


}

.dropdown-menu {
    background-color: #000000a3;
    border-radius: 0;
    padding: 0rem 0;
}
/**************************************************HOME**********************************************/
.bg-home{
    background-color: #fffff0;

}
 .transparent{
     color:#fffff0 !important;
 }

.izquierda {
    float: left;
    padding-right: 10px;
}

.derecha {
    float: left;
    padding-left: 10px;
}

.rh-ti {
    float: left;
    padding-left: 10px;
    padding-right: 10px;
}

.title-salud-proveedores{
    position: absolute;
    padding: 3%;
    left: 61%;
    background-color: black;
    bottom: 0%;
    width: 72%;
    height: 18%;
    /*padding-top: 1%;*/
}

.w30s {
    /* width: 100%; */
    float: left;
    position: relative;
    padding-right: 11.5px;
    padding-left: 10px;
    margin-top: 22px;
     /*cursor: pointer;*/
}

.w50 {
    width: 49.9%;
    margin-top: 22px;
}

.aBlack:visited, .aBlack:hover, .aBlack:focus, .aBlack:active {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.aBlack:link{
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}

.mar-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.span-home {
    color: #a2c2cd;
    font-size: 16px;
    margin-bottom: 4px;
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: 0.06em;
}

.btn-home{
    width: 100px;
    height: 40px;
    background-color: #a2c2cd;
    text-transform: uppercase;
    font-size: 14px;
    padding-top: 10px;
    border-radius: 2px;
    float: right;
    margin-top: 180px;
    margin-right: -50px;
    color: #000;
    text-align: center;
    font-weight: 400;
}

.padd20 {
    padding-left: 24px;
    padding-right: 22px;
}

.rgba-black-strong1 {
    background-color: #0000008f;
    width: 95%;
    height: 100%;
    margin-top: -10px;
}

.texto-encima{
    position: absolute;
    top: 10px;
    left: 10px;
    color: #000;
    font-size: 30px;
    padding: 0px 80px;
    font-weight: 500;
}
.contenedor{
    position: relative;
    /* display: inline-block; */
    text-align: center;
}

.avisos{
    width: 100%;
    /* height: 400PX; */
    margin: 0 auto;
    display: table;
    text-align: -webkit-center;
    /* background-color: rgba(0, 0, 0, 0.199); */
    padding: 0 30px;
    cursor: pointer;
}

.av{
    display:table-cell;
	vertical-align:middle;
}


/**********************************************BIBLIOTECA**********************************************/
.dropdown {
    position: relative;
    display: inline-block;
    color: white;
}
.dropDownContainer {
    background-color: #a2c2cd;
    padding-top: 10px;
    padding-bottom: 0px;
}

.dropDownContainer2 {
    background-color: #e5e5e1;
    padding-top: 0px;
    padding-bottom: 15px;
}

.dropDownContainer div {
    font-size: 17px;
}

.icon-white, .span-white {
    color: white;
    font-family: Futura-LT-Book, Futura, Helvetica, Arial, sans-serif
}

.center-page{
    margin: 0 auto;
    text-align: center;
}

.btn-pagination {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
   padding: 5px 10px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0.375rem;
    border: 0;
    border-radius: 0.125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    font-size: 14px !important;

}
.active-page {
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    background-color: #000 !important;
    letter-spacing: 0.03em;
    font-size: 16px !important;
    color: #fff;
}

.no-active-page {
    letter-spacing: 0.03em;
    font-size: 16px !important;
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    cursor: pointer;
}

.w90 {
    width: 90%;
}

.aaa {
    font-size: 16px;
    margin-bottom: 2px;
    cursor: pointer;
    color: #2f2f2f;
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.05em;
}

.search-field {
    z-index: 999;
    background-color: #a2c2cd;
    border: solid 1px #a2c2cd;
    color: #ffffff;
    font-size: 17px;
    /* border-radius: 50px; */
    height: 20px;
    text-align: center;
}

.search-field:focus {
    outline: none;
}

.spin-grande {
    font-size: 500px;
}

.spiner-centro {
    text-align: center;
    padding-bottom: 10%;
    padding-top: 10%;
}

.hide {
    display: none!important;
}

.show {
    display: block;
}

.Category {
    flex-grow: 1;
    border: 0;
    color: #fff;
    background: transparent;
    font-size: 15px;
    line-height: 55px;
    position: relative;
    /* letter-spacing: 0.075em; */
    font-family: Futura-LT-Book, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 2px 10px 7px;
    -webkit-appearance: none;
    outline: 0;
}

.serch-news {
    margin-top: 7px;
    text-align: right;
}

.input-buscador{
    background-color: #a2c2cd;
    width: fit-content;
    padding: 8px;
    border-radius: 50px;
    float: right;
}

.hrb63 {
    width: 90%;
    border-color: #000;
}
.hrb64 {
    width: 90%;
    border-color: #fff;
}
.categories {
    padding-top: 10px;
    padding-left: 73px;
}

.col-categories-news{
    position: relative;
    min-height: 1px;
    width: 100%;
}

.message-news3 {
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.01em;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.5;
    color: black;
    margin-top: 10px;
    margin-bottom: 0px;
}

::-webkit-input-placeholder {
    font-style: italic;
}

.font-future {
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    padding: 50px;
}

#company {
    min-width: 215px;
    max-width: 220px;
}

#companyss {
    left: -35px;
}

.alto-libros{
    height: 320px;
    padding: 10px 30px;
}



.texto-centro {
    text-align: center;
}
.circle {
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-bottom: 6px;
    border-radius: 5px;
    background-color: #fff;
}

.no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.bg-slider{
    background-image: url("../src/Initial/images/bg-slider.png");
    background-color: #a2c2cd;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-pages{
    background-image: url("../src//Initial/images/bg-pages.png");
    background-size: cover;
}

.padd-80{
    padding-left: 80px;
    padding-right: 80px;
}


.btn-news{
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px;
    width: 150px;
    border-radius: 10px;
}
/****************************************FOOTER**********************************/
.border-foo{
    border-top: solid #000 10px;
    background-image: url("./Initial/images/bg-sitemap.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.btns-footer {
    color: #fff0 !important;
}

.link-social {
    color: #204d86;
    text-transform: uppercase;
    cursor: pointer;
}

.link-social:visited {
    color: #204d86;
}

.link-social:hover {
    color: #204d86;
}


.link-footer-1 {
    color: #204d86;
    text-transform: uppercase;
    cursor: pointer;
}

.link-footer-1:visited {
    color: #204d86;
}

.link-footer-1:hover{
    color: #204d86;
    text-decoration: underline;
}



.aFooter:visited, .aFooter:hover, .aFooter:focus, .aFooter:active  {
    font-family: Futura-LT-light;
    color: #204d86;
    margin-top: 5px;
    font-size: 13px;
    letter-spacing: 0.03em;
}

.aFooter {
    font-family: Futura-LT-light;
    color: #fff;
    margin-top: 5px;
    font-size: 13px;
    letter-spacing: 0.03em;
    font-weight: bold;
}

.h6-Footer {
    list-style: none;
    color: #fff;
    font-size: 14px;
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
}





.footer-div {
    margin-bottom: 30px;
}
.footer-font {
    font-family: Futura-LT-Book, Futura, Helvetica, Arial, sans-serif;
    color: #fff;
}

.no-white{
    color: #fff;
    font-size: 15px;
}

.si-white{
    font-family: Futura-LT-Book, Futura, Helvetica, Arial, sans-serif;
    color: #fff;
    font-size: 17px;
    line-height: 1.5;
}

.centrar-pie{
    text-align: left;
}



/*******footer redes**************/
.bg-footer-social{
    background-color: #000;
}

.topnav {
    overflow: hidden;
    width: fit-content;
  }

  .topnav2, .foo-fb, .foo-tw, .foo-mm, .foo-ct, .foo-cal, .foo-insta {
    border-radius: 56px;
    height: 35px !important;
    width: 35px;
    float: left;
    margin-left: 20px;
  }

  .foo-fb{
      background-color: #315c97;
      background-image: url("./Initial/images/ICONO_FACEBOOK.png");
    background-repeat: no-repeat;
    background-size: 35px;
  }

  .foo-tw{
      background-color: #39a9e0;
      background-image: url("./Initial/images/ICONO_TWITTER.png");
    background-repeat: no-repeat;
    background-size: 35px;
  }

  .foo-insta{
    background-color: #39a9e0;
    background-image: url("./Initial/images/ICONO_INSTAGRAM.png");
  background-repeat: no-repeat;
  background-size: 35px;
}

  .foo-mm{
    background-color: #8ec2d1;
    background-image: url("./Initial/images/ICONO_MERCADITO.png");
    background-repeat: no-repeat;
    background-size: 35px;
  }

  .foo-ki{
    background-color: #fff;
    background-image: url("./Initial/images/kiosco.jpg");
    background-repeat: no-repeat;
    background-size: 35px;
  }

  .foo-ct{
      background-color: #fff;
      background-image: url("./Initial/images/ICONO_CORREO.png");
    background-repeat: no-repeat;
    background-size: 35px;}

  .foo-cal{
    background-color: #fff;
    background-image: url("./Initial/images/ICONO_CLENDARIO.png");
    background-repeat: no-repeat;
    background-size: 35px;
    }

  .foo-tog{
        background-color: #fff;
        background-image: url("./Initial/images/togetther.png");
        background-repeat: no-repeat;
        background-size: 35px;
    }


    .foo-fiori{
        background-color: #fff;
        background-image: url("./Initial/images/timeshet.jpg");
        background-repeat: no-repeat;
        background-size: 35px;
    }

  .red-ss{
    text-align: center;
    padding-top: 6px;
  }

  .center {
    margin: auto;
    width: fit-content;
    padding: 10px;
    height: 60px;
  }


  .mmccann{
    background: #333;
    color: #fff;
    display: block;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    position: relative;
    text-align: center;
    width: 35px;
    border-radius: 50%;
}
/*********SEARCH************/
.titleBack {
    text-align: center;
    background-color: #566670;
    color: white;
    padding: 8px;
    text-transform: uppercase;
    font-size: 16pt;
}

.leia-mas{
    color: #1b4e86!important;
    text-decoration: underline;
    font-size: 18px;
    font-weight: bold;
}


/*************IPG***************/
.img-card3 {
    margin-top: 20px;
    margin-left: 36%;
}

.bgb4{
    margin-top: 25px;
    /* min-height: 200px; */
    background-color: white;
    width: 120%;
    margin-left: -5%;
    /* padding: 25px 20px 20px 40px; */
}

/*************SALUD**************/

.img-card4 {
    margin-top: 20px;
    margin-left: 10%;
}

/*************FORMATOS**************/
.img-file{
    margin-left: 30%;
    width: 35%;
}

.img-card5 {
    margin-top: 20px;
    margin-left: 10%;
    width: 25%;
}

.bgbf {
    margin-left: -30%;
}

/**************CONOCE A**************/
.img-conoce{
    margin-left: 6%;
    width: 80%;
}

/******BUSCANDO*********/
.btn-black {
    background-color: #000000 !important;
    color: #fff !important;
}


/***********EL CORCHO***********/

.bg-corcho {
    /* filter: invert(100%);
    -ms-filter: invert(100%); */
    background-image: url("./Initial/images/ICON_4.png");
    padding: 50px 15px 0px 15px;
    background-repeat: no-repeat;
    height: 410px;
    background-position: center;
    /* -webkit-transition: all 0.5s ease-in-out; */
  }


.img-corcho{
    width: 200px !important;
    margin: 0 auto;
}

.info-corcho{
    float: left;
    padding-left: 10px;
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.06em;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    color: #fff;
}


.bg-iconos{
    background-color: #000;
    background-image: url("./Initial/images/bg-icons.png");
    background-repeat: no-repeat;
    background-size: contains;
}

/*******************LOS QUE LLEGAN ***********/

.flip-card {
    background-color: transparent;
    /* width: 100%; */
    height: 350px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #bbb;
    color: black;
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
  }

  .title-flip {
    font-family: Futura-LT-Heavy,Futura,Helvetica,Arial,sans-serif;
    font-weight: 400;
    letter-spacing: .2em;
    position: absolute;
    padding: 5% 0;
    margin-top: 5%;
    left: 5%;
    font-size: 14px!important;
    line-height: 1.3;
    text-transform: uppercase;
    width: 90%;
    text-align: left;
}
.ingresos img {
    width: 100%;
    height: max-content;
    margin-left: 0px !important;
    margin-right: 0px !important;
    filter: grayscale(100%);
}

.sinopsis_description {
    font-family: 'Futura LT W01 Book', Futura, Helvetica, Arial, sans-serif;
    position: absolute;
    top: 0;
    bottom: 23px;
    width: 100%;
    height: 350px;
    background: white;
    color: #fff;
    visibility: hidden;
    opacity: 0.1;
    /* transition: opacity .2s, visibility .2s; */
}

.img_llegan {
    position: relative;
    /* height: 350px; */
    padding-left: 0px !important;
    padding-right: 0px !important;
}  


.img_llegan:hover .sinopsis_description {
    visibility: visible;
    background-color: #1b4e86;
    opacity: 0.8;
}

.nombre-llegan{
    font-size: 19px;
}

.sinopsis{
    font-family: 'Futura LT W01 Book', Futura, Helvetica, Arial, sans-serif !important;
    font-size: 15px;
    text-transform: none;
    letter-spacing: initial;
}


/******************noticias*****************************/
.content-news-h1 {
    font-size: 32px;
    font-weight: bolder;
    padding-top: 40px;
    text-transform: capitalize;
}

/*******************ERROR 404***********/

.text-big {
    font-size: 125px;
    line-height: 115px;
    font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.1em;
}

.espacing {
    color: black;
    padding: 100px;
}

.error404 {
    font-size: 20px;
    text-transform: uppercase;
    font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.03em;
    max-width: 300px;
    margin: 0 auto;
}

.error404-p {
    margin-top: 20px;
    font-size: 12px;
    font-weight: bold;
    font-family: Futura-LT-Book, Futura, Helvetica, Arial, sans-serif;
    font-weight: normal;
    letter-spacing: 0.075em;
    text-align: center;
}


/********************FLECHAS CARRUSEL**********************/
.slick-next {
    right: 25px !important;
}
.slick-prev {
    left: 25px !important;
    z-index: 2 !important;
}
.slick-initialized  .slick-slide.slick-active {
    z-index : 1 ;
 }

 .slick-slide img {
    display: block;
    width: 100%;
}

.slick-dots {
    bottom: 2px !important;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #234e78 !important;
    background-color: #234e78;
}

.slick-dots li button{
    color: #fff !important;
    background-color: #234e78;
    border: solid 2px #fff;
    border-radius: 50px;
    opacity: 1;
}

.slick-dots li button:before{
    color: #fff !important;
    background-color: #fff;
    border: solid 2px #fff;
    border-radius: 50px;
    opacity: 1;
}




/* YOUR CSS simplified */
.carousel-inner > .item > img, .carousel-inner > .item > a > img {
    display: block;
    height: auto;
    width: 100%;
    line-height: 1;
}

/*Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
CSS from:       http://codepen.io/transportedman/pen/NPWRGq
and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
Inspired from:  http://codepen.io/Rowno/pen/Afykb*/
.carousel-fade .carousel-inner .item {
opacity: 0;
transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
left: 0;
opacity: 0;
z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
opacity: 1;
}
.carousel-fade .carousel-control {
z-index: 2;
}

/*WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
Need to override the 3.3 new styles for modern browsers & apply opacity*/
@media all and (transform-3d), (-webkit-transform-3d) {
.carousel-fade .carousel-inner > .item.next,
.carousel-fade .carousel-inner > .item.active.right {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-inner > .item.prev,
.carousel-fade .carousel-inner > .item.active.left {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-inner > .item.next.left,
.carousel-fade .carousel-inner > .item.prev.right,
.carousel-fade .carousel-inner > .item.active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
}








































@media all and (max-width: 1024px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px), only screen and (min-device-pixel-ratio: 2) and (max-width: 1024px), only screen and (min-resolution: 192dpi) and (max-width: 1024px), only screen and (min-resolution: 2dppx) and (max-width: 1024px) {
    .izquierda {
        float: center;
        padding-bottom: 10px;
        padding-right: 0px;
    }
    .derecha {
        float: center;
        padding-left: 0px;
    }
    .w50 {
        /* padding-left: 10px; */
        width: 100%;
    }


    .margin-slider{
        margin-top: 160px;
    }

    .logo-mccann-home {
        height: 101px;
    }



    .bgb2 {
        margin-top: 5px;
        min-height: 50px;
        width: 100%;
        margin-left: 0%;
        padding: 0px 0px 0px 20px;
    }

    .bgb4 {
        margin-top: 5px;
        min-height: 50px;
        width: 100%;
        margin-left: 0%;
        padding: 0px 0px 0px 20px;
    }

    .Blue {
        font-size: 10px;
    }

    .bgb {
        margin-top: 25px;
        /* min-height: 200px; */
        background-color: white;
        width: 100%;
        margin-left: 0%;
        padding: 25px 20px 20px 20px;
    }
    .message-news {
        letter-spacing: 0.01em;
        font-size: 16px;
    }

    .btn-floating {
        text-align: center;
        color: #fff;
        position: fixed;
        right: 40px;
        bottom: 20px;
        cursor: pointer;
        z-index: 1;
    }
    .btn-floating2 {
        text-align: center;
        color: #fff;
        position: fixed;
        right: 0px;
        bottom: 20px;
        cursor: pointer;
        z-index: 1;
    }

    .input-buscador{
        width: inherit;
    }

    .posted-on {
        font-size: 8px;
        color: #9c9c9c;
        padding-left: 0px;
    }
    .pjus {
        padding-left: 10px;
        padding-right: 10px;
        letter-spacing: 0.06em;
        font-size: 14px;
        line-height: 1.5;
        text-align: justify;
    }
    #Capa_1 {
        padding-left: 0%;
        width: 100%;
        height: 1000px;
    }
    .img-card2 {
        margin-left: 20%;
        width: 60%;
        /* max-height: 118px; */
        min-height: 118px;
    }
    .hrb4 {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 0px;
        float: center;
    }
    .no-padd {
        padding-right: 0px !important;
        padding-left: 10px !important;
    }
    .itemMB2 {
        font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
        letter-spacing: 0.12em;
        font-size: 13px !important;
        text-transform: uppercase;
        text-align: center;
        color: #ffffff !important;
        cursor: pointer;
        padding-top: 5px;
    }
    .itemMB {
        font-family: Futura-LT-Heavy, Futura, Helvetica, Arial, sans-serif;
        letter-spacing: 0.12em;
        font-size: 20px !important;
        text-transform: uppercase;
        text-align: center;
        color: #a2c2cd !important;
        cursor: pointer;
        padding-top: 5px;
    }
    .hrb {
        border-top: solid 3px #a2c2cd;
        width: 100%;
    }

    .dropDownContainer2 {
        background-color: #e5e5e1;
        margin-left: 40px !important;
        padding-top: 10px;
        padding-bottom: 5px;
        width: 80% !important;
    }
    .serch-news {
        margin-top: 7px;
        margin-left: -25px;
        text-align: right;
    }
    .Category {
        margin-top: 7px;
        margin-left: 0px !important;
        flex-grow: 1;
        border: 0;
        color: #000;
        background: transparent;
        font-size: 15px;
        line-height: 55px;
        position: relative;
        font-family: Futura-LT-Book, Futura, Helvetica, Arial, sans-serif;
        font-weight: normal;
        letter-spacing: 0.075em;
        padding: 2px 10px 7px;
        -webkit-appearance: none;
        outline: 0;
    }
    .categories {
        padding-top: 10px;
        padding-left: 20px;
    }
    .cat-new {
        padding-bottom: 10px;
        padding-left: 5px;
    }
    figure.slider img {
        width: 100%;
        height: 280px;
    }
    .carousel-inner img {
        width: 100%;
       /* min-height: 280px;
        max-height: 280px;*/
    }

    .rh-ti {
        float:initial;
    }

    .span-home {
        color: #a2c2cd;
        font-size: 10px;
        margin-bottom: 4px;
        font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
        font-weight: normal;
        letter-spacing: 0.06em;
    }
    .titleBack {
        text-align: center;
        background-color: #566670;
        color: white;
        padding: 8px;
        text-transform: uppercase;
        font-size: 10pt;
    }
    

    .site {
        color: #918f8f;
        padding: 0px !important;

    }
    .sitesFooter {
        width: 100% !important;
    }



    .h5, h5 {
        font-size: 12px !important;
    }
    .h6-Footer {
        list-style: none;
        color: #fff;
        font-size: 13px;
        font-family: Futura-LT-Medium, Futura, Helvetica, Arial, sans-serif;
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: 3;
    }

    .aFooter {
        font-family: Futura-LT-light;
        color: #585858;
        margin-top: 5px;
        font-size: 10px;
        letter-spacing: 0.03em;
        font-weight: bold;
    }
    .margin-top30 {
        margin-top: 10px;
    }
    .hrb5 {
        border-top: solid 3px #000;
        width: 5%;
        padding-bottom: 0px;
    }
    .hr-sweet {
        margin-top: 0px;
        margin-bottom: 10px;
        border: 0;
        border-top: 1px solid#ffffff66;
    }


    .visibility{
        visibility: hidden;
    }

    #age-row{
        text-align: center;
    }





}



@media (max-width: 576px){

     .centrar-pie{
         text-align: center;
     }

}


@media (max-width: 991px){
    .dropdown-item {
        
        border-bottom: none;
    
    }

    

}


@media (max-width: 1024px){
    .logo-mw-home{
       width: 230px;
   }

}




@media (min-width: 992px){
    .col-categories-news {
    width:13.999997%;
    }

}



@media (min-width: 1024px){
    .col-categories-news {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .margin-search{
        margin-top: 20px;
    }

    /* .modal-dialog {
        width: 800px !important;
    } */
    /* .modal-open .modal {
        overflow-x: hidden;
        overflow-y: hidden !important;
    } */
}

@media (min-width: 991px) and  (max-width: 1200px){
    .btn-home{
        margin-top: 140px;
    }
    .img__wrap2 {
        position: relative;
        height: 220px;
    }
    .proveedores {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 15px !important;
    }
    .hrbLEA {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .btn-proveedores {
        margin-top: 30px !important;
    }
    .texto-alto {
        height: 80px !important;
    }

}
@media (min-width: 501px) and  (max-width: 990px){
    .btn-home{
        margin-top: 170px;
    }

}

@media (min-width: 445px) and  (max-width: 500px){
    .btn-home{
        margin-top: 80px;
    }

}

@media (min-width: 401px) and  (max-width: 445px){
    .btn-home{
        margin-top: 200px;
    }


}

@media (min-width: 320px) and  (max-width: 400px){
    .btn-home{
        margin-top: 170px;
    }
    .img__wrap2 {
        position: relative;
        height: 220px;
    }

    .texto-encima{
        font-size: 20px;
    }
}

@media (min-width: 768px) and  (max-width: 991px){
    .img__wrap2 {
        position: relative;
        height: 180px;
    }
    .proveedores {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 11px !important;
    }
    .hrbLEA {
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .btn-proveedores {
        margin-top: 20px !important;
    }
    .texto-alto {
        height: 30px !important;
    }

}

@media (min-width: 576px) and  (max-width: 767px){
    .img__wrap2 {
        position: relative;
        height: 130px;
    }
    .proveedores {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        /* background-color: white; */
        font-size: 8px !important;
        }
        .texto-alto {
            height: 80px !important;
        }
}


@media (min-width: 428px) and  (max-width: 575px){
    .img__wrap2 {
        position: relative;
        height: 300px;
    }

}

/*****************/



.iwSUvz {
    width: 250px !important;
    max-height: 42px !important;
}

.yellows{
    color: #fffff0 !important;
}



  .licalendar{
    padding: 40px 20px;
      margin-left: 21px;
  }

  .title-calendar{
      font-size: 20px;
      font-weight: bold;
  }



  /* @media (min-width: 1200px){
.container {
    max-width: 1200px ;
}} */

@media (max-width: 1199px) and (min-width: 994px){

.licalendar{
    padding: 40px 3px;
      margin-left: 21px;
  }


}
.rgba-black-strong2 {
    background-color: #000000b8;
}

.rgba-black-strong3 {
    width: 95%;
    height: 100%;
    margin-top: -10px;
}







[class*="entypo-"]:before {
    font-family: "entypo", sans-serif;
 }

.redes-ss {
    list-style: none;
    margin: 0;
    padding: 0;
 }

 .padd-redes{
    padding: 10px 10px 0px 0px;
 }

#sticky-social {
    right: 0;
    position: fixed;
    top: 125px;
    z-index: 9999;
 }
 #sticky-social a {
    background: #333;
    color: #fff;
    display: block;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    position: relative;
    text-align: center;
    width: 35px;
    border-radius: 50%;
 }
 #sticky-social a span {
    line-height: 35px;
    right: -210px;
    position: absolute;
    text-align: center;
    width: 255px;
    /* border-radius: 20.5% 0 0 20.5%; */
    margin-right: -70px;
    border-radius: 56px;
    color: #fff;
 }
 #sticky-social a:hover span {
    right: 100%;

 }
 #sticky-social a[class*="facebook"],
 #sticky-social a[class*="facebook"]:hover,
 #sticky-social a[class*="facebook"] span {
     background: #315c97;
     background-image: url("./Initial/images/ICONO_FACEBOOK.png");
     background-repeat: no-repeat;
     background-size: 35px;}

 #sticky-social a[class*="twitter"],
 #sticky-social a[class*="twitter"]:hover,
 #sticky-social a[class*="twitter"] span {
     background:  #39a9e0;
     background-image: url("./Initial/images/ICONO_TWITTER.png");
     background-repeat: no-repeat;
     background-size: 35px;
    }

#sticky-social a[class*="instagram"],
#sticky-social a[class*="instagram"]:hover,
#sticky-social a[class*="instagram"] span {
        background:  #39a9e0;
        background-image: url("./Initial/images/ICONO_INSTAGRAM.png");
        background-repeat: no-repeat;
        background-size: 35px;
       }

 #sticky-social a[class*="mercadito"],
 #sticky-social a[class*="mercadito"]:hover,
 #sticky-social a[class*="mercadito"] span {
     background:  #8ec2d1;
     background-image: url("./Initial/images/ICONO_MERCADITO.png");
  background-repeat: no-repeat;
  background-size: 35px;
    }

    #sticky-social a[class*="kiosko"],
    #sticky-social a[class*="kiosko"]:hover,
    #sticky-social a[class*="kiosko"] span {
        background:  #fff;
        color: #000;
        background-image: url("./Initial/images/kiosco.jpg");
     background-repeat: no-repeat;
     background-size: 35px;
       }

    #sticky-social a[class*="together"],
    #sticky-social a[class*="together"]:hover,
    #sticky-social a[class*="together"] span {
        background:  #151c1c;
        color: #fff;
        background-image: url("./Initial/images/togetther.png");
        background-repeat: no-repeat;
        background-size: 35px;
    }

    #sticky-social a[class*="timeshet"],
    #sticky-social a[class*="timeshet"]:hover,
    #sticky-social a[class*="timeshet"] span {
        background:  #fff;
        color: #000;
        background-image: url("./Initial/images/timeshet.jpg");
        background-repeat: no-repeat;
        background-size: 35px;
    }

       #sticky-social a[class*="entrenamientos"],
       #sticky-social a[class*="entrenamientos"]:hover,
       #sticky-social a[class*="entrenamientos"] span {
           background:  #fff;
           color: #000;
           background-image: url("./Initial/images/soporteit.png");
        background-repeat: no-repeat;
        background-size: 35px;
          }

 #sticky-social a[class*="calendariohome"],
 #sticky-social a[class*="calendariohome"]:hover,
 #sticky-social a[class*="calendariohome"] span {
     background:  #fff;
    background-image: url("./Initial/images/ICONO_CLENDARIO.png");
    background-repeat: no-repeat;
    background-size: 35px;}

 #sticky-social a[class*="contactohome"],
 #sticky-social a[class*="contactohome"]:hover,
 #sticky-social a[class*="contactohome"] span {
     background: #fff;
     background-image: url("./Initial/images/ICONO_CORREO.png");
    background-repeat: no-repeat;
    background-size: 35px; }


    .padre-transform{
        background: #000;
        height: 100vh;
        min-height:600px;
      }
       
      .padre-transform > div{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 50%;
        text-align: center;
      }

      .boton-login{
          
    background: #a2c2cd;
    border: none;
    border-radius: 4px;
    color: #000;
    padding: 5px;
      }